import React, { useState } from "react"; // importing React and useState hook
import { Container, Form, Button, Card } from "react-bootstrap"; // importing bootstrap components for UI
import axios from "axios"; // requiring axios for making HTTP requests
import { useNavigate } from "react-router-dom"; // importing useNavigate for navigation
import { jwtDecode } from "jwt-decode";

function Login() {
  // state to store form data
  const [formData, setFormData] = useState({ email: "", password: "" });
  const navigate = useNavigate(); // initialize navigate function for redirecting

  // handle form field changes and update state
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value }); // spread formData and update the changing field
  };

  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // prevent default form submission behavior
    try {
      // send POST request to backend for login
      const response = await axios.post("https://stage3-web.98rxsz.easypanel.host/auth/login", formData);
      const { token, message } = response.data;

      // Save user data and token in localStorage
      localStorage.setItem("token", token); // storing JWT token in localStorage
      // Decode the token to extract user data
      const decodedToken = jwtDecode(token);
      const userData = {
        username: decodedToken.username, // Make sure this matches the claim name in your token payload
        isAdmin: decodedToken.isAdmin, // Make sure this matches the claim name in your token payload
      };

      // Save user data in localStorage
      localStorage.setItem("user", JSON.stringify(userData));
      alert(message); // show success message

      // Redirect to the homepage after successful login
      navigate("/");
    } catch (error) {
      // show an error alert if login fails
      alert("Login failed: " + (error.response?.data?.error || "An unexpected error occurred"));
    }
  };

  return (
    <Container className="d-flex justify-content-center mt-5">
      {" "}
      {/* main container for the login card */}
      <Card style={{ width: "30rem" }} className="shadow-lg">
        {" "}
        {/* styled card with shadow */}
        <Card.Body>
          <h3 className="text-center text-primary mb-4">Login</h3> {/* heading for the login form */}
          <Form onSubmit={handleSubmit}>
            {" "}
            {/* form submission handler */}
            <Form.Group controlId="formEmail" className="mb-3">
              {" "}
              {/* email input group */}
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" placeholder="Enter email" onChange={handleChange} required /> {/* input field for email */}
            </Form.Group>
            <Form.Group controlId="formPassword" className="mb-3">
              {" "}
              {/* password input group */}
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" placeholder="Enter password" onChange={handleChange} required /> {/* input field for password */}
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              {" "}
              {/* submit button */}
              Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Login; // exporting the Login component to be used in other parts of the app

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Nav, Navbar as BootstrapNavbar, Button } from "react-bootstrap";

function Navbar() {
  const [user, setUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, [location]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser(null);
    window.location.reload();
  };

  return (
    <BootstrapNavbar bg="primary" variant="dark" expand="lg" className="mb-4">
      <Container>
        <BootstrapNavbar.Brand as={Link} to="/">
          Social Awareness App
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {!user ? (
              <>
                <Nav.Link as={Link} to="/login">
                  Login
                </Nav.Link>
                <Button as={Link} to="/register" variant="outline-light" className="ms-2">
                  Sign Up
                </Button>
              </>
            ) : (
              <>
                <Nav.Link as={Link} to="/post-campaign">
                  Post Campaign
                </Nav.Link>
                {user.isAdmin && (
                  <Nav.Link as={Link} to="/admin-dashboard">
                    Admin Dashboard
                  </Nav.Link>
                )}
                <span className="navbar-text text-light me-3">Logged in as {user.username}</span>
                <Button variant="outline-light" onClick={handleLogout}>
                  Log Out
                </Button>
              </>
            )}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
}

export default Navbar;

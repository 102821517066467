import React, { useState, useEffect } from "react";
import { Container, Table, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";

function AdminDashboard() {
  const [campaigns, setCampaigns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [formData, setFormData] = useState({ title: "", description: "", businessName: "", status: "" });

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await axios.get("https://stage3-web.98rxsz.easypanel.host/campaigns/pending", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setCampaigns(response.data);
      } catch (error) {
        alert("Failed to load campaigns.");
      }
    };
    fetchCampaigns();
  }, []);

  const handleApprove = async (id) => {
    try {
      await axios.put(
        `https://stage3-web.98rxsz.easypanel.host/campaigns/${id}/approve`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      alert("Campaign approved.");
      setCampaigns(campaigns.filter((campaign) => campaign.campaign_id !== id));
    } catch (error) {
      alert("Failed to approve the campaign.");
    }
  };

  const handleEditClick = (campaign) => {
    setSelectedCampaign(campaign);
    setFormData({
      title: campaign.title,
      description: campaign.description,
      businessName: campaign.business_name || "",
      status: campaign.status,
    });
    setShowModal(true);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`https://stage3-web.98rxsz.easypanel.host/campaigns/${selectedCampaign.campaign_id}`, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      alert("Campaign updated successfully.");
      setShowModal(false);
      window.location.reload(); // Refresh the campaign list after update
    } catch (error) {
      alert("Failed to update campaign.");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this campaign?")) {
      try {
        await axios.delete(`https://stage3-web.98rxsz.easypanel.host/campaigns/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        alert("Campaign deleted successfully.");
        setCampaigns(campaigns.filter((campaign) => campaign.campaign_id !== id));
      } catch (error) {
        alert("Failed to delete the campaign.");
      }
    }
  };

  return (
    <Container className="mt-4">
      <h2>Admin Dashboard</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map((campaign, index) => (
            <tr key={index}>
              <td>{campaign.title}</td>
              <td>{campaign.description}</td>
              <td>
                <Button variant="success" onClick={() => handleApprove(campaign.campaign_id)}>
                  Approve
                </Button>{" "}
                <Button variant="info" onClick={() => handleEditClick(campaign)}>
                  Edit
                </Button>{" "}
                <Button variant="danger" onClick={() => handleDelete(campaign.campaign_id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for editing a campaign */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle" className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} required />
            </Form.Group>
            <Form.Group controlId="formDescription" className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} rows={3} required />
            </Form.Group>
            <Form.Group controlId="formBusinessName" className="mb-3">
              <Form.Label>Business Name (optional)</Form.Label>
              <Form.Control type="text" value={formData.businessName} onChange={(e) => setFormData({ ...formData, businessName: e.target.value })} />
            </Form.Group>
            <Form.Group controlId="formStatus" className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control type="text" value={formData.status} onChange={(e) => setFormData({ ...formData, status: e.target.value })} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AdminDashboard;

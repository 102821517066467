import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Card, Button } from "react-bootstrap";
import axios from "axios";

function CampaignDetail() {
  const { id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [participated, setParticipated] = useState(false);

  useEffect(() => {
    const fetchCampaignDetails = async () => {
      try {
        const response = await axios.get(`https://stage3-web.98rxsz.easypanel.host/campaigns/${id}`);
        setCampaign(response.data);
        setIsLoading(false);
      } catch (error) {
        alert("Failed to load campaign details.");
        setIsLoading(false);
      }
    };

    const checkParticipationStatus = async () => {
      try {
        const response = await axios.get(`https://stage3-web.98rxsz.easypanel.host/campaigns/${id}/participation-status`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setParticipated(response.data.participated);
      } catch (error) {
        console.error("Failed to check participation status:", error);
      }
    };

    fetchCampaignDetails();
    checkParticipationStatus();
  }, [id]);

  const handleParticipate = async () => {
    try {
      await axios.post(
        `https://stage3-web.98rxsz.easypanel.host/campaigns/${id}/participate`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      alert("You have successfully participated in this campaign.");
      setParticipated(true);
    } catch (error) {
      alert("Failed to participate in the campaign. You may have already participated.");
    }
  };

  if (isLoading) {
    return <p className="text-center">Loading...</p>;
  }

  if (!campaign) {
    return <p className="text-center">Campaign not found.</p>;
  }

  return (
    <Container className="mt-4">
      <Card className="shadow-sm">
        <Card.Body>
          <h2 className="text-primary">{campaign.title}</h2>
          <Card.Text>{campaign.description}</Card.Text>
          {campaign.business_name && <Card.Subtitle className="mb-3 text-muted">Posted by: {campaign.business_name}</Card.Subtitle>}
          {!participated ? (
            <Button variant="primary" onClick={handleParticipate}>
              Participate in Campaign
            </Button>
          ) : (
            <p className="text-success mt-3">You have already participated in this campaign.</p>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default CampaignDetail;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import Login from "./components/Login";
import Register from "./components/Register";
import CampaignForm from "./components/CampaignForm";
import AdminDashboard from "./components/AdminDashboard";
import CampaignDetail from "./components/CampaignDetail";
import Navbar from "./components/Navbar";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/post-campaign" element={<CampaignForm />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/campaign/:id" element={<CampaignDetail />} />
      </Routes>
    </Router>
  );
}

export default App;

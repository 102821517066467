import React, { useState } from "react";
import { Container, Card, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function CampaignForm() {
  const [formData, setFormData] = useState({ title: "", description: "", businessName: "" });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://stage3-web.98rxsz.easypanel.host/campaigns", formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      alert("Campaign submitted successfully.");
      navigate("/");
    } catch (error) {
      alert("Failed to submit the campaign.");
    }
  };

  return (
    <Container className="mt-4 d-flex justify-content-center">
      <Card style={{ width: "40rem" }} className="shadow-sm">
        <Card.Body>
          <h2 className="text-center mb-4">Post a Campaign</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formTitle" className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" placeholder="Enter campaign title" onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="formDescription" className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" name="description" placeholder="Enter campaign description" onChange={handleChange} rows={3} required />
            </Form.Group>
            <Form.Group controlId="formBusinessName" className="mb-3">
              <Form.Label>Business Name (optional)</Form.Label>
              <Form.Control type="text" name="businessName" placeholder="Enter your business name" onChange={handleChange} />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Submit Campaign
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default CampaignForm;

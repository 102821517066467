import React, { useState } from "react"; // importing React and useState hook
import { Container, Form, Button, Card } from "react-bootstrap"; // importing Bootstrap components for UI
import axios from "axios"; // requiring axios for HTTP requests
import { useNavigate } from "react-router-dom"; // importing useNavigate for redirecting

function Register() {
  // state to store form data
  const [formData, setFormData] = useState({ username: "", email: "", password: "" });
  const navigate = useNavigate(); // initialize navigation function

  // handle input changes and update form data state
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value }); // update state for the specific input field
  };

  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // prevent default form submission behavior
    try {
      // send POST request to backend for registering new user
      await axios.post("https://stage3-web.98rxsz.easypanel.host/auth/register", formData);
      alert("Registration successful. Please log in."); // show success alert

      // redirect to the login page
      navigate("/login");
    } catch (error) {
      // show error alert if registration fails
      alert("Registration failed: " + (error.response?.data?.error || "An unexpected error occurred"));
    }
  };

  return (
    <Container className="d-flex justify-content-center mt-5">
      {" "}
      {/* main container for the registration card */}
      <Card style={{ width: "30rem" }} className="shadow-lg">
        {" "}
        {/* styled card with shadow effect */}
        <Card.Body>
          <h3 className="text-center text-primary mb-4">Register</h3> {/* heading for registration form */}
          <Form onSubmit={handleSubmit}>
            {" "}
            {/* form submission handler */}
            <Form.Group controlId="formUsername" className="mb-3">
              {" "}
              {/* username input group */}
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" name="username" placeholder="Enter username" onChange={handleChange} required /> {/* input field for username */}
            </Form.Group>
            <Form.Group controlId="formEmail" className="mb-3">
              {" "}
              {/* email input group */}
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" placeholder="Enter email" onChange={handleChange} required /> {/* input field for email */}
            </Form.Group>
            <Form.Group controlId="formPassword" className="mb-3">
              {" "}
              {/* password input group */}
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" placeholder="Enter password" onChange={handleChange} required /> {/* input field for password */}
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              {" "}
              {/* submit button */}
              Register
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Register; // exporting the Register component to use in other parts of the app

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

function HomePage() {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await axios.get("https://stage3-web.98rxsz.easypanel.host/campaigns/approved");
        setCampaigns(response.data);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
        alert("Failed to load campaigns.");
      }
    };
    fetchCampaigns();
  }, []);

  return (
    <Container className="mt-4">
      <h1 className="text-center text-primary mb-4">Featured Campaigns</h1>
      <Row>
        {campaigns.map((campaign) => (
          <Col key={campaign.campaign_id} md={6} lg={4} className="mb-4">
            <Card className="shadow-sm h-100">
              <Card.Body>
                <Card.Title className="text-primary">{campaign.title}</Card.Title>
                <Card.Text>{campaign.description}</Card.Text>
                {campaign.business_name && <Card.Subtitle className="mb-2 text-muted">By: {campaign.business_name}</Card.Subtitle>}
                <Link to={`/campaign/${campaign.campaign_id}`} className="btn btn-primary mt-2">
                  View Details
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default HomePage;
